.home {
    min-height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .contentBox {
    position: relative;
    z-index: -1;
    padding: 4rem;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    border-left: none;
  }

  .contentBox::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 2px;
    width: 50%;
    background: linear-gradient(90deg, #18D9D9, #29D9B9, #3DF2BF, #404040, #0D0D0D);
  }
  
  .gradient-text {
    background: linear-gradient(45deg,#404040, #18D9D9, #29D9B9, #3DF2BF);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent; /* For non-webkit browsers */
    -webkit-text-fill-color: transparent; /* For webkit browsers */
    display: inline-block;
    font-size: 20px; /* To enable the gradient on inline elements like <span> */
  }